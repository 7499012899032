<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="4">
        <v-text-field
          outlined
          dense
          label="Branch Name"
          v-model="record.BPLName"
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-autocomplete
          outlined
          dense
          v-model="record.LocationCode"
          :items="locations"
          item-text="Location"
          item-value="id"
          label="Location"
        ></v-autocomplete>
      </v-col>

      <v-col cols="4">
        <v-text-field
          outlined
          dense
          label="External Reference"
          v-model="record.ExtRef"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-btn
          color="accent"
          @click="sendData"
          :loading="loader"
        >
          <v-icon left>mdi-content-save</v-icon>Save
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
export default {
  props: {
    initial: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    record: {},
    drivers: [],
    locations: [],
    loader: false,
    dimensions: [],
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
  },
  methods: {
    init(val) {
      this.record = { ...val };
    },
    setDate(date) {
      this.record.date = date;
    },
    sendData() {
      this.loader = true;
      this.$emit("data", this.record);
    },
    getLocations() {
      const self = this;
      this.$store
        .dispatch("get", `/locations`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.locations = res.ResponseData;
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getLocations();
  },
};
</script>